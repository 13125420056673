module.exports = {
  siteTitle: "Hi! I'm Thomas!",
  siteDescription: 'CV & Portfolio',
  keyWords: ['CV', 'resume', 'portfolio'],
  authorName: 'Thomas Lau',
  githubUsername: 'thomaslty',
  authorAvatar: '/images/avatar.jpeg',
  authorDescription: `
  Developer, passionate about what I do. Always interested in new technologies.<br /><br />
  Love to tinker. Recently into homeserver / homelab.<br /><br />
  If you have a job invitation, please do not hesitate to contact me <a href="mailto: ttthomaslau12@gmail.com"><strong>ttthomaslau12@gmail.com<strong></a>
  `,
  skills: [
    {
      name: 'HTML',
      level: 100,
    },
    {
      name: 'CSS',
      level: 100,
    },
    {
      name: 'Javascript',
      level: 100,
    },
    {
      name: "JQuery",
      level: 100
    },
    {
      name: 'React Native',
      level: 100,
    },
    {
      name: 'React.js',
      level: 100,
    },
    {
      name: 'Node.js',
      level: 100,
    },
    {
      name: "Python",
      level: 100
    },
    {
      name: "C#",
      level: 100,
    },
    {
      name: '.NET WPF',
      level: 100,
    },
    {
      name: "LINQ",
      level: 100,
    },
    {
      name: "Entity Framework",
      level: 100
    },
    {
      name: "MySQL",
      level: 100
    },
    {
      name: "MongoDB",
      level: 100
    },
    {
      name: "Docker",
      level: 100
    },
    {
      name: "Java",
      level: 90
    },
    {
      name: 'TypeScript',
      level: 80,
    },
    {
      name: "Ruby",
      level: 80
    },
    {
      name: 'Ruby on Rails',
      level: 80,
    },
    /* more skills here */
  ],
  jobs: [
    /* more jobs here */
    {
      company: "Bride Union",
      begin: {
        month: "Feb",
        year: "2022"
      },
      duration: "1 year (2022 - 2023)",
      occupation: "Full Stack Developer",
      type: "Full-time",
      description:
        [
          "●	Revamped a high-traffic project (over 10k monthly visits) that offering product and ingredient safety assessments to the public, including a reimagined React Native mobile app and an infrastructure migration from Django/Flask/MySQL to HTML/Node.js/MongoDB",
          "●	Designed and developed mobile applications using React Native",
          "●	Built responsive websites using HTML, CSS, Javascript and JQuery",
          "●	Developed backend API server and database using RESTful, Node.js and MongoDB",
          "●	Created image search tool using Python and Machine Learning techniques",
          "●	Streamlined DevOps processes by implementing a CI/CD pipeline with GitHub Actions",
          "●	Redesigned and migrated various databases, such as transitioning from MSSQL to MongoDB and from MySQL to MongoDB",
          "●	Delivered exceptional IT support to clients, including Windows/Linux troubleshooting, cloud server configuration, and LAMP stack setup, etc"
        ]
    },
    {
      company: 'Hong Kong Electric',
      begin: {
        month: 'Jul',
        year: '2021',
      },
      duration: "1 year (2021 - 2022)",
      occupation: 'Programmer',
      type: "Full-time",
      description:
        [
          "●	Refined and optimized backend code architecture to enhance modularity, streamlining implementation for various attributes and reducing repetitive coding tasks",
          "●	Developed and maintained multiple mobile applications using React Native & Redux",
          "●	Developed and maintained desktop applications and backend server using C#, .NET, Entity Framework and LINQ",
          "●	Collaborated effectively with clients to continuously enhance the user experience for both mobile and desktop applications",
        ]
    },
    {
      company: 'Bride Union',
      begin: {
        month: 'Apr',
        year: '2021',
      },
      duration: null,
      occupation: 'Developer | Part-time',
      // type: "Part-time",
      description:
        [
          "●	Designed and developed mobile applications using React Native",
          "●	Migrated database from MSSQL to MongoDB using Node.js & Javascript",
          "●	Designed shell script to automate various process",
          "●	Provided IT support for clients, like setting up servers using Ubuntu/Nginx/MySQL/PHP, linux troubleshooting, etc",
          "●	Developed small tools using javascript"
        ]
    },
    {
      company: 'Convoy',
      begin: {
        month: 'Jun',
        year: '2020',
      },
      duration: "2 months",
      occupation: 'IT Support Assistant | Contract',
      // type: "Contract",
      description:
        [
          "●	Provided IT support for clients, eg. resetting password using Active Directory",
          "●	Designed CMD script to automate various process",
        ]
    },
    {
      company: 'KEEP (Knowledge & Education Exchange Platform)',
      begin: {
        month: 'May',
        year: '2019',
      },
      duration: "2 years (2019 - 2021)",
      occupation: 'Programmer | Intern & Part-time',
      // type: "Intern & Part-time",
      description:
        [
          "●	Maintained and updated new features for frontend and backend using React.js and Ruby on Rails",
        ],
    },
  ],
  portfolio: [
    {
      image: '/images/blog.png',
      description: 'Blog',
      url: 'https://blog.hiiamthomas.me',
    },
    {
      image: '/images/peanute.png',
      description: 'College Project: Peanute Android APP',
      url: 'https://peanute.app/',
    },
    {
      image: '/images/sunkids-admission.png',
      description: 'Work Project: Sunkids Admission',
      url: 'https://admissions.sunkids.edu.hk/',
    },
    {
      image: '/images/peak-capital.png',
      description: 'Freelance Project: Peak Capital',
      url: 'https://peak-capital.hk/',
    },
    {
      image: "/images/github.png",
      description: "Github Repo",
      url: "https://github.com/thomaslty"
    },
    {
      image: "/images/linkedin.png",
      description: "Linkedin",
      url: "https://www.linkedin.com/in/thomaslty/"
    }
    /* more portfolio items here */
  ],
  social: {
    linkedin: 'https://www.linkedin.com/in/thomaslty/',
    github: 'https://github.com/thomaslty',
    email: 'ttthomaslau12@gmail.com',
  },
  siteUrl: 'https://hiiamthomas.me',
  pathPrefix: '/', // Note: it must *not* have a trailing slash.
  siteCover: '/images/cover.jpeg',
  googleAnalyticsId: 'UA-157463725-1',
  background_color: '#ffffff',
  theme_color: '#25303B',
  fontColor: '#000000cc',
  enableDarkmode: true, // If true, enables dark mode switch
  display: 'minimal-ui',
  icon: 'src/assets/favicon.png',
  headerLinks: [
    {
      label: 'Home',
      url: '/',
    },
    {
      label: 'Portfolio',
      url: '/portfolio',
    },
  ],
}
